import React from 'react'

import Layout from '../components/layout'

export default function privacy() {
  return (
    <Layout>
      <div className="header">
        <div className="container">
          <div className="row">
            <div className="columns twelve">
              <h1 className="center-text">Privacy Policy</h1>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ marginTop: '100px', marginBottom: '60px' }}
      >
        <div className="row">
          <div className="twelve columns">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
              fugit amet temporibus deserunt quaerat hic consequuntur expedita
              esse laborum tempora animi cumque sed omnis sit, reprehenderit
              quo, illo dolores repudiandae. Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Optio, velit impedit assumenda quod
              porro, hic saepe culpa quidem enim molestiae deleniti non
              perspiciatis perferendis architecto voluptate. Error quidem quia
              dolore!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
              fugit amet temporibus deserunt quaerat hic consequuntur expedita
              esse laborum tempora animi cumque sed omnis sit, reprehenderit
              quo, illo dolores repudiandae. Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Optio, velit impedit assumenda quod
              porro, hic saepe culpa quidem enim molestiae deleniti non
              perspiciatis perferendis architecto voluptate. Error quidem quia
              dolore!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
              fugit amet temporibus deserunt quaerat hic consequuntur expedita
              esse laborum tempora animi cumque sed omnis sit, reprehenderit
              quo, illo dolores repudiandae. Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Optio, velit impedit assumenda quod
              porro, hic saepe culpa quidem enim molestiae deleniti non
              perspiciatis perferendis architecto voluptate. Error quidem quia
              dolore!
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro
              fugit amet temporibus deserunt quaerat hic consequuntur expedita
              esse laborum tempora animi cumque sed omnis sit, reprehenderit
              quo, illo dolores repudiandae. Lorem ipsum dolor, sit amet
              consectetur adipisicing elit. Optio, velit impedit assumenda quod
              porro, hic saepe culpa quidem enim molestiae deleniti non
              perspiciatis perferendis architecto voluptate. Error quidem quia
              dolore!
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
